import merge from 'lodash.merge';
import { UsStateValue } from 'lib/common/states';
import microcopy from './en';

const getText = (stateCode?: UsStateValue) => {
  // @ts-ignore
  if (stateCode && microcopy[stateCode]) {
    // We merge our microcopy objects into an empty object as to not mutate them (lodash.merge mutates the first object passed in)
    // @ts-ignore
    return merge({}, microcopy.common, microcopy[stateCode]);
  }
  return microcopy.common;
};

export default getText;
