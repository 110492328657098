import styled from 'styled-components';
import theme from 'lib/styles/theme';
import media from 'lib/styles/media';

export const ComplianceDisclaimerWrapper = styled.div`
  padding: 20px;
  background-color: ${theme.colors.white};

  a {
    font-weight: 600;
    color: ${theme.colors.text};
  }

  ${media.medium`
    margin: 32px auto 0;
    max-width: 500px;
    padding: 0 16px;
    background-color: transparent;
    p, a {
      color: ${theme.colors.white};
    }
  `}
`;
