import styled from 'styled-components';
import theme from 'lib/styles/theme';
import media from 'lib/styles/media';

export const Background = styled.div`
  background: ${theme.colors.jackpocketBlue};

  ${media.medium`
    min-height: 100vh;
    padding: 50px 0;
  `}
`;

export const PageContent = styled.div`
  margin: 0 auto;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.white};
  box-shadow: 0 8px 16px rgb(0 0 0 / 0.07);

  ${media.medium`
    border-radius: 16px;
    max-width: 500px;
  `}
`;

export const Wrapper = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${theme.colors.bg};

  > main {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
  }
`;
