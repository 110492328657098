import React, { Fragment } from 'react';
import { FiCheck } from 'react-icons/fi';
import { StepRow, Step, StepNumber, Divider } from './styles';

type Props = {
  currentStep?: number;
};

const steps = ['Sign Up', 'Verify Phone', 'Verify Age'];

export const StepIndicator = ({ currentStep }: Props) => (
  <StepRow>
    {steps.map((item, idx) => {
      const isActive = currentStep === idx + 1;
      const isComplete = currentStep && currentStep > idx + 1;
      return (
        <Fragment key={item}>
          <Step>
            <StepNumber
              isActive={isActive}
              data-testid={`step_indicator_${idx + 1}`}
            >
              {isComplete ? <FiCheck /> : idx + 1}
            </StepNumber>
            <span>{item}</span>
          </Step>
          {idx < steps.length - 1 && <Divider />}
        </Fragment>
      );
    })}
  </StepRow>
);

export default StepIndicator;
