import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  href: string;
  ['data-testid']: string;
  rel?: string;
  target?: string;
}

const SITE_EXIT_MESSAGE = `Heads up! You're about to leave the Jackpocket website. Jackpocket can't guarantee the safety of any content you view outside the Jackpocket site or app.`;

const ExternalLink = ({
  children,
  href,
  rel = 'noreferrer',
  target = '_blank',
  ...props
}: Props) => {
  const handleExternalLinkClick = (event: any) => {
    if (!window.confirm(SITE_EXIT_MESSAGE)) {
      event.stopPropagation();
      event.preventDefault();
    }
  };

  return (
    <a
      data-testid={props['data-testid']}
      href={href}
      target={target}
      rel={rel}
      onClick={handleExternalLinkClick}
    >
      {children}
    </a>
  );
};

export default ExternalLink;
