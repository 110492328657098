import styled, { css } from 'styled-components';
import theme from 'lib/styles/theme';
import { rem, transparentize } from 'polished';
import media from 'lib/styles/media';

const { white, text } = theme.colors;

export const StepRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 32px;
  padding: 0 4px;
`;

export const Step = styled.div`
  display: flex;
  align-items: center;
  color: ${white};
  font-weight: 600;
  flex-shrink: 0;

  > span {
    font-size: ${rem(14)};
  }
`;

export const StepNumber = styled.div<{
  isActive: boolean;
}>`
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  border: 1px solid ${transparentize(0.6, white)};
  border-radius: 50%;
  font-size: ${rem(14)};
  color: ${white};

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: ${white};
      color: ${text};
      border-color: ${white};
      box-shadow: 0 4px 4px rgb(11 85 150 / 0.15),
        inset -3px -3px 0 rgb(30 136 229 / 0.15);
    `}

  ${media.mlarge`
height: 30px;
  width: 30px;
  font-size: ${rem(16)};
  `}
`;

export const Divider = styled.div`
  height: 1px;
  width: 4px;
  margin: 0 2px;
  background-color: ${transparentize(0.6, white)};
  ${media.mlarge`
  display:block;

  width: 20px;
  margin: 0 8px;
  `}
`;
