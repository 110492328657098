import React from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import ExternalLink from 'components/atoms/external-link';
import Text from 'components/atoms/text';
import { useGameLocation } from 'lib/atomic-state';
import getText from 'lib/common/lang';
import { ComplianceDisclaimerWrapper } from './styles';

const GamblerHotline = dynamic(
  () => import('components/atoms/gambler-hotline'),
  { ssr: false },
);

const WITH_DISCLAIM_PAGES = ['/auth/signup', '/auth/signin'];

export const SignUpDisclaimer = () => {
  const { gameLocation } = useGameLocation();
  const { disclaimer } = getText(gameLocation?.value);

  return (
    <ComplianceDisclaimerWrapper>
      <Text as="p" variant="caption" mb={15} textAlign="center">
        {disclaimer.age_requirement} If you or someone you know has a gambling
        problem and wants help, call <GamblerHotline />.
      </Text>
      <Text as="p" variant="caption" mb={30} textAlign="center">
        Message and data rates may apply. You will receive one verification
        message per login. By continuing, you agree to&nbsp;
        <a href="https://jackpocket.com/tos/" target="_blank" rel="noreferrer">
          Jackpocket&apos;s Terms of Service
        </a>
        &nbsp;and&nbsp;
        <a
          href="https://jackpocket.com/privacy/"
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy
        </a>
        &nbsp;as well as our banking partner&nbsp;
        <ExternalLink
          data-testid="dwolla_tos"
          href="https://www.dwolla.com/legal/tos/"
        >
          Dwolla&apos;s Terms of Service
        </ExternalLink>
        &nbsp;and&nbsp;
        <ExternalLink
          data-testid="dwolla_privacy_policy"
          href="https://www.dwolla.com/legal/privacy/"
        >
          Privacy Policy.
        </ExternalLink>
      </Text>
      {gameLocation?.value === 'WV' && (
        <Text as="p" variant="caption" mb={15} textAlign="center">
          Jackpocket is a courier service and is not affiliated with the West
          Virginia Lottery. All West Virginia lottery tickets are purchased
          through a licensed West Virginia Retailer. The West Virginia Lottery
          and the State of West Virginia does not guarantee, or have any
          liability related to, courier customer activities. A receipt is not a
          validly issued game ticket. Must be 18 years or older to play.
        </Text>
      )}
    </ComplianceDisclaimerWrapper>
  );
};

const DisclaimerOnboard = () => {
  const router = useRouter();

  if (WITH_DISCLAIM_PAGES.includes(router.pathname)) {
    return <SignUpDisclaimer />;
  }

  return null;
};

export default DisclaimerOnboard;
