import React, { ReactNode } from 'react';
import WithFooter from 'components/layouts/with-footer';
import HeaderOnboard from 'components/molecules/header-onboard';
import DisclaimerOnboard from 'components/molecules/disclaimer-onboard';
import { Background, PageContent } from './styles';

type Props = {
  children: ReactNode;
};

export const OnboardingLayout = ({ children }: Props) => (
  <WithFooter>
    <Background>
      <HeaderOnboard />
      <PageContent>{children}</PageContent>
      <DisclaimerOnboard />
    </Background>
  </WithFooter>
);
export default OnboardingLayout;
