import { GAME_IDS } from 'lib/constants/global';

export type MicroCopy = {
  [key: string]: any;
};

const microcopy = {
  common: {
    label: {
      play_cta: 'Play now',
      nav_play_link: 'Play',
      nav_scratch_games_link: 'Scratch Games',
    },
    disclaimer: {
      affiliation: 'Jackpocket is not affiliated with any State Lottery.',
      age_requirement: 'Must be 18 or older to play the lottery.',
      age_verification_profile:
        'We hate acting like a bouncer but state laws require that you must be over the age of 18 to play the lottery.',
      help_contact:
        'If you or someone you know has a gambling problem and wants help, call 1-800-GAMBLER.',
      zip_code_address:
        'Zip code is the address lived at the longest since turning 18',
    },
    hotline: {
      display_number: '1-800-GAMBLER',
      tel_link_number: '+18004262537',
    },
    orders: {
      status: {
        big_winner: `Your ticket has met the big winner prize threshold and you are required to claim your prize from the state lottery. Contact us to get your winning ticket.`,
        processing: `Your tickets are being processed. This might take a little while but we'll let you know when your tickets are ready to view.`,
        voided: 'There was a problem processing your order.',
        waiting_results: 'Check back in a little while.',
      },
      void_reason: {
        default: {
          title: 'Ticket Voided',
          detail: 'This ticket has been voided.',
        },
        liability_limit_exceeded: {
          title: 'Liability Limit Exceeded',
          detail: 'This number combination was sold out.',
        },
      },
    },
  },
  landing: {
    hero: {
      title: `Order official state lottery tickets`,
      btn_label: 'Get started',
    },
    hiw: {
      title: 'How Jackpocket Works',
      item1:
        'See a scan of your ticket and get an email confirmation with your ticket serial number.',
      item2:
        'Jackpocket checks the results for you and lets you know when you’ve won!',
      item3:
        'You can transfer your winnings to a bank account or use them to play again.',
    },
    faq: {
      title: 'Frequently Asked Questions',
      items: [
        {
          trigger: 'Is Jackpocket legal?',
          text1: `Yes! Jackpocket complies with all applicable state laws and
            regulations. The Jackpocket app allows users to order lottery
            tickets that are then fulfilled on their behalf at a licensed
            lottery retailer.`,
          text2: `Think of Jackpocket like your really good friend who is always
            available to take your orders for lottery tickets and always makes
            sure to secure the exact ticket you requested in time for the
            drawing!`,
        },
        {
          trigger: 'What happens if I win?',
          text1: `Smaller prizes—usually up to $600 depending on the threshold amount
            in your state—go straight to your Jackpocket account. From there, if
            you wish to withdraw your winnings, you can make a Payout request
            and we will transfer the funds to your bank account.`,
          text2: `For large prizes above the threshold amount in your state, we
            securely transfer your paper ticket to you so you can claim your
            prize from the state lottery.`,
        },
        {
          trigger: 'Are there any fees?',
          text1: `As we're a courier service, we charge a service fee when you
            fund your account to cover our costs, and so that you can continue
            to experience the most convenient, safe, and fun way to play the
            lottery!`,
          text2:
            'The fee amount will always be made available to you&nbsp;<strong><em>before</em> </strong> making a transaction. Visit Add Funds in the side menu to check it out.',
          text3: `The good news? There is no monthly fee, no fee to sign up, and we
            don't take anything from your winnings — whatever you win is
            100% yours!`,
        },
        {
          trigger: 'How do I get my winnings?',
        },
      ],
    },
    cta: {
      title: 'Ready to Play?',
      btn_label: 'Get started',
    },
    prizes: {
      won_amount: '$500,000,000',
      won_text: 'in total prizes won',
    },
  },
  play: {
    super_pick: {
      education: {
        [GAME_IDS.megaMillions]: [
          {
            imagePath: '/icons/play/bonus_ball_mega.svg',
            displayText:
              'Super Pick generates a play for each Mega Ball number.',
          },
          {
            imagePath: '/icons/play/prize.svg',
            displayText: 'Win at least $2 just for matching the Mega Ball!',
          },
          {
            imagePath: '/icons/play/random.svg',
            displayText:
              'Each standard number is used at least once and picked randomly.',
          },
        ],
        [GAME_IDS.powerBall]: [
          {
            imagePath: '/icons/play/bonus_ball_power.svg',
            displayText:
              'Super Pick generates a play for each Powerball number.',
          },
          {
            imagePath: '/icons/play/prize.svg',
            displayText: 'Win at least $4 just for matching the Powerball!',
          },
          {
            imagePath: '/icons/play/random.svg',
            displayText:
              'Each standard number is used at least once and picked randomly.',
          },
        ],
      },
    },
  },
  AZ: {
    disclaimer: {
      age_requirement: 'Must be 21 or older to play the lottery.',
      age_verification_profile:
        'We hate acting like a bouncer but state laws require that you must be over the age of 21 to play the lottery.',
      zip_code_address:
        'Zip code is the address lived at the longest since turning 21',
    },
  },
  FL: {
    disclaimer: {
      affiliation:
        'Jackpocket is not affiliated with and is not an agent of the Florida Lottery.',
      help_contact:
        'Play Responsibly. Gambling Problem? 888-ADMIT-IT (236-4848).',
    },
    hotline: {
      display_number: '888-ADMIT-IT',
      tel_link_number: '+18882364848',
    },
  },
  MA: {
    disclaimer: {
      help_contact:
        'If you or someone you know has a gambling problem and wants help call, 1-800-327-5050.',
    },
    hotline: {
      display_number: '1-800-327-5050',
      tel_link_number: '+18003275050',
    },
  },
  ME: {
    disclaimer: {
      affiliation:
        'Jackpocket is not affiliated with and is not an agent of the Maine State Lottery.',
      help_contact:
        'Keep it Fun. Play Responsibly. Gambling Problem? Call 1-800-GAMBLER.',
    },
  },
  NE: {
    disclaimer: {
      age_requirement: 'Must be 19 or older to play the lottery.',
      age_verification_profile:
        'We hate acting like a bouncer but state laws require that you must be over the age of 19 to play the lottery.',
      zip_code_address:
        'Zip code is the address lived at the longest since turning 19',
    },
  },
  NY: {
    label: {
      play_cta: 'Order now',
      nav_play_link: 'Games',
    },
    disclaimer: {
      help_contact:
        'If you or someone you know has a gambling problem and wants help call, 1-877-8-HOPE-NY or text HOPENY (467369).',
    },
    hotline: {
      display_number: '1-877-8-HOPE-NY',
      tel_link_number: '+18778467369',
    },
  },
  OR: {
    disclaimer: {
      help_contact:
        'If you or someone you know has a gambling problem and wants help, call 1-877-MYLIMIT.',
    },
    hotline: {
      display_number: '1-877-MYLIMIT',
      tel_link_number: '+18776954648',
    },
  },
  PR: {
    disclaimer: {
      affiliation:
        'Jackpocket is not affiliated with and is not an agent of the Puerto Rico Lottery.',
      help_contact:
        'If you or someone you know has a gambling problem and wants help, call 1-800-981-0023.',
    },
    hotline: {
      display_number: '1-800-981-0023',
      tel_link_number: '+18009810023',
    },
  },
};

export default microcopy;
