import React from 'react';
import { useRouter, NextRouter } from 'next/router';
import Logo from 'components/atoms/icons/logo';
import StepIndicator from 'components/molecules/step-indicator';
import { TopBar } from './styles';

export const HeaderOnboard = () => {
  const router: NextRouter = useRouter();

  const onBoardStep: { [key: string]: number } = {
    '/auth/signup': 1,
    '/auth/verify': 2,
    '/promotions': 2,
    '/auth/profile': 3,
  };

  const showSteps = onBoardStep[router.pathname] && !router.query.sign_in;

  if (!router.pathname) {
    return null;
  }

  return (
    <TopBar>
      <Logo isLight />
      {showSteps && (
        <StepIndicator currentStep={onBoardStep[router?.pathname]} />
      )}
    </TopBar>
  );
};

export default HeaderOnboard;
